import React from 'react';
import PropTypes from 'prop-types';
import Helmet from 'react-helmet';
import { graphql } from 'gatsby';
import Layout from '../components/Layout';
import Share from '../components/Share';
import HtmlContent from '../components/HtmlContent';
import BlogPost from '../components/BlogPost';
import RelatedPosts from '../components/RelatedPosts';

const BlogPostTemplate = ({ data }) => {
  const {
    post: {
      html,
      frontmatter: { title, tags, image, description, dateString },
      fields: { slug },
    },
    site: {
      siteMetadata: { siteUrl, twitterHandle, twitterHashtags, title: siteTitle },
    },
  } = data;
  const { edges: posts } = data.related || { edges: [] };

  return (
    <Layout>
      <BlogPost
        className="blog-post"
        content={<HtmlContent content={html} />}
        description={description}
        image={image}
        dateString={dateString}
        tags={tags}
        title={title}
        helmet={
          <Helmet>
            <meta property="og:site_name" content={siteTitle} />
            <meta property="og:url" content={`$siteUrl}${slug}`} />
            <meta property="og:title" content={title} />
            <meta property="og:type" content="article" />
            <meta property="og:image" content={image} />
            <meta property="og:description" content={description} />
            <meta name="description" content={description} />
            <meta name="twitter:title" content={title} />
            <meta name="twitter:description" content={title} />
            <meta name="twitter:site" content={twitterHandle} />
            <meta name="twitter:card" content="summary_large_image" />
            <title>{title} | {siteTitle}</title>
          </Helmet>
        }
        share={
          <Share
            socialConfig={{
              twitterHandle,
              config: {
                url: `${siteUrl}${slug}`,
                title,
              },
            }}
            tags={twitterHashtags.concat(tags)}
          />
        }
      />

      {/* posts always includes self */ posts.length > 1  && <RelatedPosts posts={posts} currentSlug={slug} />}
    </Layout>
  );
};

BlogPostTemplate.propTypes = {
  data: PropTypes.shape({
    site: PropTypes.object.isRequired, /* TODO: site shape */
    post: PropTypes.object.isRequired, /* TODO: post shape */
    related: PropTypes.shape({
      edges: PropTypes.arrayOf(PropTypes.shape({
        node: PropTypes.object, /* TODO: post shape */
      })),
    }),
  }).isRequired,
};

export default BlogPostTemplate;

// TODO: 1) Find a better place to store this fragment (it's also used in blog-list.js)
//       2) Eliminate the need to reach into frontmatter by migrating all of it
//          into fields using https://github.com/Undistraction/gatsby-plugin-node-fields
//          (this will also provide for defaults and possible transforms on tags)
//          (note that teaser property is already manually placed into a field in gatsby-node.js)
// Note: changes in this query fragment require restart?
export const blogPostFrontmatter = graphql`
  fragment BlogPostFrontmatter on MarkdownRemark {
    frontmatter {
      dateString: date(formatString: "MMMM DD, YYYY")
      title
      description
      image
      tags
      category
      templateKey
    }
  }
`;

export const pageQuery = graphql`
  query BlogPostByID($id: String!, $tags: [String]) {
    site {
      siteMetadata {
        title
        siteUrl
        twitterHandle
        twitterHashtags
      }
    }
    post: markdownRemark(id: { eq: $id }) {
      id
      html
      fields {
        slug
      }
      ...BlogPostFrontmatter
    }
    related: allMarkdownRemark(
      sort: { order: DESC, fields: [frontmatter___date] },
      filter:  { frontmatter: { tags: { in: $tags } } }
      limit: 3
    ) {
      edges {
        node {
          excerpt(pruneLength: 155)
          id
          fields {
            slug
          }
          ...BlogPostFrontmatter
        }
      }
    }
  }
`;
