import PropTypes from 'prop-types';
import React from 'react';
import { Link } from 'gatsby';
import { kebabCase } from 'lodash';
import Helmet from 'react-helmet';

const BlogPost = ({
  className,
  content,
  description,
  tags,
  title,
  image,
  dateString,
  helmet,
  share,
}) => {

  let smallImage = image;
  if (image && image.match(/ucarecdn/)) {
    smallImage = `${image}-/format/auto/-/scale_crop/1000x1000/center/-/progressive/yes/-/quality/lightest/`;
    image += '-/format/auto/-/scale_crop/1920x1080/center/-/progressive/yes/-/quality/lightest/';
  }

  // Note that without wrapping the style in Helmet, while it works in development,
  // in production, the double-quotes inside url() become &quot;.
  // However, we can't use Helmet in Netlify CMS which doesn't pass helmet prop here
  const WrapIn = helmet ? Helmet : ({ children }) => children;
  return (
    <article className={className}>
      {helmet || ''}
      {image &&
        <WrapIn>
          <style>{`
                #banner {
                  background-position: 50% top;
                  background-image: url("${smallImage}");
                }
                .blog-post #banner {
                  background-image: url("${image}");

                }
                @media (max-width: 767px) {
                  .blog-post #banner {
                    background-image: url("${smallImage}");
                  }
                }
              `}
          </style>
        </WrapIn>
      }
      <section
        id="banner"
        className="g-bg-cover g-bg-size-cover g-bg-white-gradient-opacity-v1--after"
      >
        <div className="container text-center g-pos-rel g-z-index-1 g-pb-50">
          <div className="row d-flex justify-content-center align-content-end flex-wrap g-min-height-500">
            <div className="col-lg-7 mt-auto">
              <div className="mb-5">
                <h1 className="g-color-white g-font-weight-600 g-mb-30">{title}</h1>
                <p className="lead g-color-white-opacity-0_6">{description}</p>
              </div>
              <span className="g-color-white-opacity-0_7 g-font-weight-300 g-font-size-12" style={{ display: 'none' }}>Pregnancy Coach Staff</span>
              <span className="g-color-white-opacity-0_7 g-font-weight-700 mx-3" style={{ display: 'none' }}>&#183;</span>
              <span className="g-color-white-opacity-0_7 g-font-weight-300 g-font-size-12">{dateString}</span>
            </div>
          </div>
        </div>
      </section>

      {content &&
        <section className="container g-pt-60 g-pb-60">
          <div className="row justify-content-center">
            <div className="col-lg-9">
              {content}
            </div>
          </div>
        </section>
      }

      {(tags || share) &&
        <section className="container">
          <div className="row justify-content-center">
            <div className="col-lg-9">

              {tags && tags.length ? (
                <div className="g-mb-40">
                  <ul className="u-list-inline mb-5">
                    {tags.map((tag) => (
                      <li key={tag} className="list-inline-item g-mb-10">
                        <Link
                          to={`/blog/tag/${kebabCase(tag)}/`}
                          className="u-tags-v1 g-brd-around g-brd-gray-dark-v5 g-brd-primary--hover g-color-black g-color-white--hover g-bg-primary--hover g-font-weight-600 g-font-size-12 g-rounded-50 g-py-4 g-px-15"
                        >
                          {tag}
                        </Link>
                      </li>
                    ))}
                  </ul>
                </div>
              ) : null}

              {share || ''}

            </div>
          </div>
        </section>
      }

    </article>
  );
};

BlogPost.propTypes = {
  className: PropTypes.string, /* "blog-post" when used for full article */
  content: PropTypes.node,
  description: PropTypes.string,
  tags: PropTypes.arrayOf(PropTypes.string),
  title: PropTypes.string,
  dateString: PropTypes.string,
  image: PropTypes.string,
  helmet: PropTypes.node,
  share: PropTypes.node,
};

export default BlogPost;
