import React from 'react';
import PropTypes from 'prop-types';
import {
  FacebookShareButton,
  GooglePlusShareButton,
  LinkedinShareButton,
  TwitterShareButton,
} from 'react-share';

const Share = ({ socialConfig, tags }) => (
  <div className="text-center">
    <h3 className="h6 g-font-weight-600 text-uppercase mb-3">Share:</h3>
    <ul className="list-inline g-mb-60">
      <li className="list-inline-item g-mx-2">
        <FacebookShareButton
          url={socialConfig.config.url}
          className="u-icon-v1 u-icon-slide-up--hover g-color-gray-dark-v4 g-color-facebook--hover"
        >
          <i className="g-font-size-default g-line-height-1 u-icon__elem-regular fa fa-facebook" />
          <i className="g-font-size-default g-line-height-0_8 u-icon__elem-hover fa fa-facebook" />
        </FacebookShareButton>
      </li>
      <li className="list-inline-item g-mx-2">
        <TwitterShareButton
          url={socialConfig.config.url}
          title={socialConfig.config.title}
          via={socialConfig.twitterHandle.split('@').join('')}
          hashtags={tags.map((tag) => tag.replace(/ /g, ''))}
          className="u-icon-v1 u-icon-slide-up--hover g-color-gray-dark-v4 g-color-twitter--hover"
        >
          <i className="g-font-size-default g-line-height-1 u-icon__elem-regular fa fa-twitter" />
          <i className="g-font-size-default g-line-height-0_8 u-icon__elem-hover fa fa-twitter" />
        </TwitterShareButton>
      </li>
      <li className="list-inline-item g-mx-2">
        <GooglePlusShareButton
          url={socialConfig.config.url}
          className="u-icon-v1 u-icon-slide-up--hover g-color-gray-dark-v4 g-color-google-plus--hover"
        >
          <i className="g-font-size-default g-line-height-1 u-icon__elem-regular fa fa-google-plus" />
          <i className="g-font-size-default g-line-height-0_8 u-icon__elem-hover fa fa-google-plus" />
        </GooglePlusShareButton>
      </li>
      <li className="list-inline-item g-mx-2">
        <LinkedinShareButton
          url={socialConfig.config.url}
          title={socialConfig.config.title}
          className="u-icon-v1 u-icon-slide-up--hover g-color-gray-dark-v4 g-color-linkedin--hover"
        >
          <i className="g-font-size-default g-line-height-1 u-icon__elem-regular fa fa-linkedin" />
          <i className="g-font-size-default g-line-height-0_8 u-icon__elem-hover fa fa-linkedin" />
        </LinkedinShareButton>
      </li>
    </ul>
  </div>
);

Share.propTypes = {
  socialConfig: PropTypes.shape({
    twitterHandle: PropTypes.string.isRequired,
    config: PropTypes.shape({
      url: PropTypes.string.isRequired,
      title: PropTypes.string.isRequired,
    }),
  }).isRequired,
  tags: PropTypes.arrayOf(PropTypes.string),
};

Share.defaultProps = {
  tags: [],
};

export default Share;
