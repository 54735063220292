import React from 'react';
import PropTypes from 'prop-types';
import BlogPostRelatedItem from './BlogPostRelatedItem';

const RelatedPosts = ({ posts, currentSlug }) => {
  return (
    <section className="g-bg-gray-light-v5">
      <div className="container g-pt-100 g-pb-70">
        <h3 className="h5 g-font-weight-600 text-center text-uppercase g-mb-60">Related Posts</h3>
        <div className="row">
          {posts.map(({ node: post }) => {
            const {
              frontmatter: { title, image, dateString, description },
              fields: { slug },
              excerpt,
            } = post;
            if (slug === currentSlug) {
              return null;
            }
            return (
              <BlogPostRelatedItem
                key={post.id}
                slug={slug}
                title={title}
                description={description}
                image={image}
                excerpt={excerpt}
                dateString={dateString}
              />
            );
          })}
        </div>
      </div>
    </section>
  );
};

RelatedPosts.propTypes = {
  posts: PropTypes.arrayOf(PropTypes.shape({
    node: PropTypes.object, /* TODO: post shape */
  })).isRequired,
  currentSlug: PropTypes.string,
};

export default RelatedPosts;
