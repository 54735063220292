import PropTypes from 'prop-types';
import React from 'react';
import { Link } from 'gatsby';

const BlogPostRelatedItem = ({ slug, title, dateString, description, excerpt, image }) => {
  if (image && image.match(/ucarecdn/)) {
    image += '-/format/auto/-/scale_crop/500x450/center/';
  }

  return (
    <div className="col-sm-6 col-lg-4 g-mb-30">
      <article className="u-shadow-v19 g-bg-white">
        {image &&
          <Link to={slug}>
            <img className="img-fluid w-100" src={image} alt="" />
          </Link>
        }
        <div className="g-bg-white g-pa-30">
          <span className="d-block g-color-gray-dark-v5 g-font-size-12 mb-2">{dateString}</span>
          <h2 className="h5 g-font-weight-600 mb-3">
            <Link to={slug}>{title}</Link>
          </h2>
          <p className="g-color-gray-dark-v4 g-line-height-1_8">{description || excerpt}</p>
        </div>
      </article>
    </div>
  );
};

BlogPostRelatedItem.propTypes = {
  slug: PropTypes.string.isRequired,
  title: PropTypes.string,
  dateString: PropTypes.string,
  description: PropTypes.string,
  image: PropTypes.string,
  excerpt: PropTypes.string,
};

export default BlogPostRelatedItem;
